#app {
  margin: 0;
}

.placeholder__header,
.placeholder__footer,
.placeholder__content-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder__header {
  height: 60px;
  background: white;
  color: black;
}

.placeholder__content-section {
  background: #2A5B6C;
  height: 660px;
  color: white;
}

.placeholder__footer {
  height: 260px;
  background: #384967;
  color: white;
}
