#app {
  margin: 0;
}

.placeholder__header, .placeholder__footer, .placeholder__content-section {
  justify-content: center;
  align-items: center;
  display: flex;
}

.placeholder__header {
  color: #000;
  background: #fff;
  height: 60px;
}

.placeholder__content-section {
  color: #fff;
  background: #2a5b6c;
  height: 660px;
}

.placeholder__footer {
  color: #fff;
  background: #384967;
  height: 260px;
}

/*# sourceMappingURL=index.798f660f.css.map */
